.react-datepicker__portal {
    background: none;
    left: 200px;
    top: -160px;
}

.react-datepicker {
    background-color: #4b4b4b;
}

.react-datepicker__header {
    background-color: #333333;
}

.react-datepicker__month {
    background-color: #4b4b4b;
    margin: 0;
}

.react-datepicker__day {
    color: #fff;
}

.react-datepicker__day--disabled {
    color: #ccc;
    opacity: 0.4;
}

.react-datepicker__day-name {
    color: #fff;
}

.react-datepicker__current-month {
    color: #fff;
}

.react-datepicker__day--in-range {
    background-color: #0ab025;
}

.react-datepicker__day--keyboard-selected:not([aria-disabled=true]):hover {
    background-color: #0ab025;
}

.react-datepicker__day:not([aria-disabled=true]):hover {
    background-color: #75ed88;
}

.react-datepicker__day--keyboard-selected:not(.react-datepicker__day--in-range) {
    background-color: transparent;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: #0ab025;

    &:hover {
        background-color: #0ab025;
    }
}

.react-datepicker__month-container {
    border-right: 1px solid #aeaeae;
}

.react-datepicker__day--today:not(.react-datepicker__day--in-range) {
    background-color: transparent;
}