input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #9ca3af;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  display: block;
}